.mask{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.6);
    padding: 20px;
    z-index: 3;
}
.spin{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}