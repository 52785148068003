.user_manual_main{
    /* min-width: 1200px; */
}
.main{
    padding: 20px 40px;
    background-color: #fff;
    min-height: 500px;
    border-radius: 5px;
}
.video_wrap{
    position: relative;
    width: 838px;
    height: 500px;
    margin: 0 auto;
    overflow: hidden;
}
.video_mask{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}
.btn_wrap{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    cursor: pointer;
    transition: all 0.5s;
}
.btn_wrap:hover{
    transform: scale(1.1) translate(-50%,-50%);
}