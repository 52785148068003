.faq_main {
    /* min-width: 1200px; */
}

.logs_main {
    padding: 20px 40px;
    background-color: #fff;
    border-radius: 5px;
    min-height: 500px;
}

.panel {
    border-bottom: none !important;
}

.Logsitembox {
    background: #f9f9f9;
    border-radius: 3px;
    margin-bottom: 10px;
    padding: 10px 20px;
}

.Logsitemboxhead {
    font-size: 16px;
    font-weight: 600;
    width: 120px;
    position: relative;
}


.Logsitemboxcontent {
    font-size: 16px;
    color: #666;
    word-break: break-all
}