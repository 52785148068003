.home_footer {
    /* min-width: 1200px; */
    margin-top: 10px;
}

.footer_right_wrap {
    /* width: 620px;
    height: 300px; */
    width: 100%;
    height: 100%;
    min-height: 237px;
    padding: 20px 30px;
    /* margin-left: 10px; */
    background-color: #fff;
    border-radius: 5px;
}

.img_banner {
    border-radius: 5px;
}

.notice {
    font-weight: bold;
    position: relative;
    left: 10px;
    top: -4px;
    font-size: 16px;
    color: #3a3a3a;
}

.link {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    color: #3a3a3a;
}

.time {
    color: #999;
}

.logshead {
    padding-bottom: 10px;
    border-bottom: 1px solid #d8d8d8;
}

.changeLog {
    bottom: 10px;
    height: calc(100% - 80px);
    width: calc(100% - 60px);
    position: absolute;
    overflow-y: auto;

}

.changeLog p {
    margin: 0;
    padding: 0;
}

.mymorebtn {
    display: block;
    padding: 2px 0 2px 10px;
    height: 26px;
    line-height: 20px;
    float: right;
    color: #249fec;
    cursor: pointer;
    border-radius: 2px;
}


.mymorebtn:hover {
    opacity: 0.8;
    transition: all 0.2s;
    color: #249fec;
}

.noticeList {
    width: 100%;
}

.noticeListul {
    padding: 0;
}

.noticeListitem {
    list-style: none;
    padding: 5px 0;
    color: blue;
    font-size: 15px;
    width: 100%;
    position: relative;
}

.times {
    padding-right: 15px;
    color: #000;
    width: 100px;
}

.contens {
    white-space: nowrap;
    position: absolute;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 100px;
    top: 4px;

}