.pageMain{
    margin: 0 auto;
    background: #fff;
    border-radius: 4px;
    padding: 30px;
}

.pageMainItem{
    background: #F5FAFA;
    padding: 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pageMainItemButton{
    width: 120px;
    background: #36A096;
    padding: 6px 10px;
    border: 0;
    font-size: 15px;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
}

.pageMainItemHead{
    font-size: 15px;
    font-weight: 600;
    line-height: 30px;
}

.pageMainItemLabe{
    font-size: 13px;
    color: #666;
}

.subScriptionHead{
    font-weight: bold;
    font-size: 16px;
}

.subscriptionMain{
    padding: 20px 0;
}

.subscriptionImg{
    width: 100px;
    height: auto;
    display: block;
    margin: 0 auto;
}
.subscriptionText{
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
}
.subscriptionBtns{
    margin-top: 40px;
}
.subscriptionCancel{
    width: 48%;
    height: 40px;
    color: #F84C4C;
    background: #fff;
    border: 1px solid #F84C4C;
    border-radius: 20px;
    margin-right: 2%;
    transition: all 0.5s;
    cursor: pointer;
}
.subscriptionCancel:hover,.subscriptionCancel:active{
    background: #f2f2f2 !important;
    color: #F84C4C;
    border: 1px solid #F84C4C;
}

.subscriptionConfirm{
    width: 48%;
    margin-left: 2%;
    height: 40px;
    color: #fff;
    background: #36A096;
    border: 1px solid #36A096;
    border-radius: 20px;
    transition: opacity 0.5s;
    cursor: pointer;
}

.subscriptionConfirm:hover,.subscriptionConfirm:active{
    background: #36A096 !important;
    border: 1px solid #36A096 !important;
    color: #fff !important;
    opacity: 0.7;
}

.payType{
    text-transform: capitalize;
    font-size: 15px;
    font-weight: 600;
    line-height: 30px;
}

.SuccessIcon{
    width: 80px;
    height: 80px;
    display: block;
    margin: 0 auto;
}

.SuccessIconTxt{
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
}
.SuccessIconBox{
    padding: 20px 0;
}

.NextPay{
    color: #666;
}