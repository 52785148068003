body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F0F3FB;
}

#root {
  /* min-width: 1200px; */
  background-color: #F0F3FB;
  height: 100%;
}

.feedback .ant-modal-content,
.feedback .ant-modal-header {
  background-color: #f6f5fa;
}

.PayDialogmodalcss .ant-modal-content {
  border-radius: 5px;
  overflow: hidden;
}

.PayDialogmodalcss .ant-modal-body {
  min-height: 550px;
  padding-bottom: 105px;
  position: relative;
}

.AuthorizedModalCss .ant-modal-content{
  border-radius: 5px;
  overflow: hidden;
}

.AuthorizedModalCss .ant-modal-body{
  padding: 10px 24px;
}

.reloadPageCss .ant-modal-content {
  border-radius: 5px;
  overflow: hidden;
}

.main_card_btn:focus {
  background: #36a096 !important;
}
.main_card_btn[disabled],.main_card_btn[disabled]:active,.main_card_btn[disabled]:hover,.main_card_btn[disabled]:focus{
background: #36a096 !important;
opacity: 0.8;
color: #fff !important;
}

.promotetabs {
  margin-top: 20px;
  border-radius: 2px;
}

.promotetabs .ant-tabs-tab {

  padding: 12px 60px;
  border: 1px solid #F0F3FB;
  font-size: 16px;
  border-left: 0;
  margin: 0 !important;
  transition: all 0.5s;
}

.promotetabs .ant-tabs-tab-active {
  background: #F0F3FB;

}

.promotetabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #3A3A3A !important;
}

.promotetabs .ant-tabs-tab:nth-child(1) {
  border-left: 1px solid #F0F3FB;
}

.promotetabs .ant-tabs-nav .ant-tabs-ink-bar {
  display: none;
}

.ant-tabs-nav-list {
  border-radius: 5px 5px 0 0;
}

.promotetabs .ant-tabs-nav::before {
  border-bottom: 0 !important;
}

.accountDialogmodalcss .ant-modal-content {
  border-radius: 8px;
  overflow: hidden;
}

.min-heighttabel {
  height: 100px;
}

.SubscriptionPage{
  width:75%;
  margin: 0 auto;
  min-height: 400px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.renewalDialog .ant-modal-content{
  border-radius: 8px;
  overflow: hidden;
}

.InvoicesForm .ant-form-item{
  margin-bottom: 0;
}

.defaultModule{
  background: #fff;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 16px;
}

.defaultNotDataImg{
  display: block;
  width: 200px;
  height: 200px;
  transform: translateX(-3px);
}