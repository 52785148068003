.register_main {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 1360px;
  min-height: 528px;
}
.main {
  position: absolute;
  width: 782px;
  height: 528px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.main_left {
  position: relative;
}
.left_mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.mask_top {
  text-align: center;
  padding: 80px 0 36px;
}
.wel {
  padding-top: 36px;
  font-size: 20px;
  color: #fff;
}
.mask_bottom {
  position: absolute;
  bottom: 40px;
  text-align: center;
  left: 50%;
  color: #fff;
  transform: translateX(-50%);
}
.bottom_version {
  padding-top: 20px;
}
.main_right {
  width: 460px;
  height: 100%;
  box-sizing: border-box;
  padding: 30px 50px;
  background-color: #fff;
}
