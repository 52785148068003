.input_wrap {
  display: inline-block;
  width: 100px;
  height: 100px;
  position: relative;
  border: 1px dashed #ccc;
  overflow: hidden;
  cursor: pointer;
  background-color: #f6f5fa;
}
.input_file {
  width: 1px;
  height: 1px;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.input_wrap:hover {
  border-color: #36a096;
}

.icon_wrap {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #36a096;
  font-size: 40px;
}

.img_wrap {
  display: inline-block;
  width: 100px;
  height: 100px;
  padding: 8px;
  border: 1px solid #ccc;
  margin-right: 10px;
  position: relative;
}
.img_wrap:hover .mask {
  opacity: 1;
}
.mask {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: all 0.5s;
}
