.faq_main {
    /* min-width: 1200px; */
}

.main {
    padding: 20px 40px;
    background-color: #fff;
    border-radius: 5px;
    min-height: 500px;
}

.panel {
    border-bottom: none !important;
}