.user_update{
    /* min-width: 1200px; */
}
.main{
    padding: 20px 40px;
    background-color: #fff;
    min-height: 500px;
    border-radius: 5px;
}
.form{
    /* width: 600px;
    margin: 40px 0; */
    width: 100%;
}