.case_desc{
    width: 900px;
    overflow-y: scroll;
    padding: 10px; 
    position: relative;
    border-left: 10px solid #F0F3FB;
}
.case_desc img{
    max-width: 100%;
    height: auto;
}
.main{
    padding: 10px; 
}

.empty{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
