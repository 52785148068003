.home_main {
  /* min-width: 1200px; */
}

.main {
  padding: 30px;
  background-color: #fff;
  border-radius: 5px;
}

.home_main_card {
  /* width: 300px;
    height: 280px; */
  width: 29%;
  min-height: 220px;
  height: 280px;
  /* height: auto; */
  border-radius: 5px;
  /* margin: 0 35px; */
  /* border: 1px solid #e6e6e6; */
  overflow: hidden;
  background-repeat: no-repeat;
  position: relative;
  background-image: url("~@/assets/images/card_bg220317.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.bg_wrap {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.card_content_wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 40px 0 30px;
}

.card_title {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}

.card_price {
  font-size: 30px;
  color: #36a096;
  text-align: center;
  font-weight: bold;
}

.price_num {
  font-size: 36px;
}

.card_label {
  text-align: center;
  height: 25px;
}

.main_input_active {
  width: 100%;
  padding: 35px 0 0;
}

.active_input {
  width: 75%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}

.active_input:focus {
  border-color: #36a096;
}

.active_btn {
  width: 25%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.loddingbox {
  padding: 150px 0;
  display: flex;
  justify-content: center;
}

.serverpin {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.serverpinimg {
  display: block;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 40%;
  z-index: 2;
  transform: translate(-50%, -50%);
}

.closemask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
}

.buyButton{
  display: block;
  width: 80%;
  /* min-width: 120px; */
  margin: 0 auto;
}

.NotBuyButton{
  display: block;
  width: 80%;
  background: #CCCCCC;
  color: #fff;
  margin: 0 auto;
  outline: none;
}

.NotBuyButton:hover,.NotBuyButton:active{
  display: block;
  width: 80%;
  background: #CCCCCC;
  color: #fff;
  margin: 0 auto;
}

.NotBuyButton:focus{
  display: block;
  width: 80%;
  background: #CCCCCC !important;
  color: #fff !important;
  border: 0 !important;
  margin: 0 auto;
}

.reloadPageIcon{
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto 30px;
}
.reloadPageText{
  text-align: center;
  line-height: 30px;
  font-size: 15px;
}

.reloadPageButton{
  background: #36A096;
  line-height: 38px;
  margin: 0 auto;
  display: block;
  color: #fff;
  padding: 0 32px;
  border-radius: 20px;
  border: 0;
  cursor: pointer;
  margin-bottom: 15px;
  margin-top: 10px;
}

.reloadPageButton:hover{
  opacity: 0.7;
}

.UrlLinkTo{
  color: #7bccff;
  text-align: left;
  width: 95.6%;
  display: block;
  font-size: 16px;
  margin-top: 8px;
  cursor: pointer;
}

.gongYingShan{
  height: 20px;
  width: auto;
  margin-right: 10px;
  display: inline-block;
}

.AuthorizedBox{

}

.AuthorizedItem{
  overflow: hidden;
  border-bottom: 1px solid #E4E4E4;
  padding: 30px 0;
  font-size: 15px;
  color: #3A3A3A;
}
.AuthorizedItem:last-child{
  border-bottom: 0;
}

.AuthorizedItemLeft{
  float: left;
}

.AuthorizedItemRight{
  float: right;
  text-decoration: underline;
  color: #249FEC;
  cursor: pointer;
}

