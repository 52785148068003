@import "~antd/dist/antd.css";

.ant-layout {
  /* padding: 20px; */
  min-width: 600px;
}

.common_main {
  /* min-width: 600px;
  max-width: 1200px; */
  margin: 0 auto;
  font-size: 16px;
}

.main_row {
  min-width: 820px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f3fb;
}

/*控制整个滚动条*/
::-webkit-scrollbar {
  background-color: lightgray;
  width: 4px;
  height: 10px;
  background-clip: padding-box;
}

/*滚动条两端方向按钮*/
::-webkit-scrollbar-button {
  background-color: #aaa;
  width: 0px;
  height: 0px;
  border-radius: 5px;
}

/*滚动条中间滑动部分*/
::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 5px;
}

.disabled-select {
  -moz-user-select: none;
  /*火狐*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  -ms-user-select: none;
  /*IE10*/
  -khtml-user-select: none;
  /*早期浏览器*/
  user-select: none;
}

.clearfix::after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.c29 {
  color: #36a096;
}

.cf5 {
  color: #36a096;
}

.size16 {
  font-size: 16px;
}

.size18 {
  font-size: 18px;
}

.size24 {
  font-size: 24px;
}

.size28 {
  font-size: 28px;
}

.size32 {
  font-size: 32px;
}

.size36 {
  font-size: 36px;
}

.size42 {
  font-size: 42px;
}

.size14 {
  font-size: 14px;
}

.pointer {
  cursor: pointer;
}

.bold {
  font-weight: 700;
}

.faq_panel .ant-collapse-header {
  background-color: #fff !important;
}

.faq_panel .ant-collapse-content-box {
  background-color: #f5f5f5;
}

.register .create_btn {
  background-color: #36a096;
  margin-right: 20px;
}

.register .ant-btn-primary,
.register .ant-btn-primary:hover,
.register .ant-btn-primary:active {
  color: #fff;
  border-color: #36a096;
  background: #36a096;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn-primary,
.ant-btn-primary:hover {
  background-color: #36a096;
}

a:hover {
  color: #36a096;
}

.ant-btn-primary {
  border: 0;
}

.rv-notice-bar__content {
  font-size: 16px !important;
}

.rv-notice-bar {
  height: 45px !important;
}

.notbarbox {
  margin-bottom: 10px;
}

.rv-notice-bar__left-icon {
  width: 135px;
}

.leftnoticebarico {
  font-size: 16px;
}

.leftnoticebaricoimg {
  transform: translateY(3px) !important;
  margin-right: 5px !important;
}


.FeedbackPopUp{
  border-radius: 5px;
  overflow: hidden;
}

.FeedbackPopUp .ant-modal-header{
  background: #F0F3FB;
  padding: 14px 24px;
}
.FeedbackPopUp .ant-modal-body{
  padding: 20px 24px
}

.CheckBoxForInput .ant-checkbox-inner{
  border: 1px solid #36a096;
  background: #fff;
  border-radius: 0;
}

.CheckBoxForInput  .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color:#36a096;
}

.CheckBoxForInput  .ant-checkbox-checked .ant-checkbox-inner::after{
  background: none;
  border: 0;
  width: 70%;
  height: 70%;
  left: 50%;
  transform: rotateZ(0) translateX(-50%) translateY(-50%);
  background: #36a096;
  transition: none;
}

.CheckBoxForRadio  .ant-radio-inner::after{
  background: #36a096;
}

.CheckBoxForRadio .ant-radio-checked .ant-radio-inner{
  border-color: #36a096;
}

.ant-modal-header{
  background: #F0F3FB;
}

.ant-modal-header .ant-modal-title{
  font-weight: 600;
}