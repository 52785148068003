.subscriptionMain{
    padding:  0;
}
.InvoicesDownloadEmail{
    padding-bottom: 20px;
    line-height: 28px;
    font-size: 14px;
    border-bottom: 1px solid #E4E4E4;
    margin-bottom: 20px;
}
.InvoicesDownloadtxt{
    color: #249FEC;
}

.InvoicesFormItem{
    height: 38px;
    line-height: 38px;
    margin-top: 5px;
}

.FormLabel{
    color: #999999;
}

.FormLabelTxt{
    color: red;
    margin-left: 3px;
}
.FormHead{
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 10px;
}

.submitBtn{
    width: 60%;
    display: block;
    padding: 0;
    margin: 0 auto;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    margin-top: 20px;
    background: #36A096;
}

.submitBtn:hover, .submitBtn:focus{
    background: #36A096 !important;
}

.SuccessIcon{
    width: 80px;
    height: 80px;
    display: block;
    margin: 0 auto;
}

.SuccessIconTxt{
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
}
.SuccessIconBox{
    padding: 20px 0;
}