.CheckBoxForInput{
    position: relative;
    width: 14px;
    height: 14px;
}
.CheckBoxForInput::before{
    background: #fff;
    content: ' ';
    display: block;
    border: 1px solid silver;
    text-align: center;
    font-weight: bold;
    width: 100%;
    height: 100%;
    border: 1px solid #36A096;
}
.CheckBoxForInput[type="checkbox"]:checked::after {
    content: ' ';
    background: #36A096;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    transform: translateX(-50%) translateY(-50%);
}

.FromBoxHead{
    font-size: 15px;
    color: #3A3A3A;
    line-height: 24px;
    border-bottom: 1px solid #E4E4E4;
    padding: 0 0 15px;
    margin-bottom: 10px;
}

.FromItemBox{
    overflow: hidden;
}

.FromItem{
    width: 50%;
    float: left;
    padding-right: 5px;
    font-size: 13px;
    padding: 5px 0;
}

.otherBitmap{
    margin-top: 10px;
    padding-bottom: 25px;
    border-bottom: 1px solid #E4E4E4;
}

.changeaccountbtnBox{
    margin-top: 20px;
    padding-bottom: 20px;
}

.changeaccountbtn{
    width: 48%;
    border: 1px solid #36A096;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    padding:  0;
    color: #36A096;
    margin-right: 2%;
    outline: none;
}

.changeaccountbtn:hover,.changeaccountbtn:focus{
    border: 1px solid #36A096;
    color: #36A096;
    opacity: 0.8;
}

.changeaccountbtnbySubmit{
    width: 48%;
    border: 1px solid #36A096;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    padding:  0;
    background: #36A096;
    color: #fff;
    margin-left: 2%;
}

.changeaccountbtnbySubmit:hover{
    border: 1px solid #36A096;
    background: #36A096;
    color: #fff;
    opacity: 0.8;
}

.NevermindText{
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
}

.NevermindText span{
    color: #36A096;
}

.linkHead{
    margin-top: 10px;
    font-size: 15px;
    font-weight: bold;
}

.optionHead{
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 5px;
}

.radioBox{
    width: 100%;
    overflow: hidden;
}

.radioItem{
    width: 50%;
    float: left;
}

.RadioBox{
    border-bottom: 1px solid #E4E4E4;
    padding-bottom: 10px;
    margin-bottom: 20px;
}
.toCancel{
    cursor: pointer;
}