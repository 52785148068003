.user_update {
  /* min-width: 1200px; */
}

.main {
  padding: 20px 40px;
  background-color: #fff;
  min-height: 500px;
  border-radius: 5px;
}

.form {
  width: 100%;
  /* width: 600px; */
  /* margin: 40px 0; */
}

.loginName {
  line-height: 36px;
}

.copybutton {
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  font-weight: normal;
  padding: 0 10px;
  margin-left: 30px;
  color: #fff;
  cursor: pointer;
  background: #36a096;
  border-radius: 3px;
}