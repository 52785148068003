.modal_btn_wrap {
    margin: 20px 0 10px;
    text-align: center;
    padding-top: 20px;
    position: absolute;
    bottom: 5px;
    width: 590px;
}

.modal_btn {
    margin-right: 2%;
    width: 48%;
    height: 45px !important;
}

.modal_btn:focus {
    background: #36a096
}

.Cancelbtn {
    height: 45px;
    margin-left: 2%;
    border-radius: 50px;
    width: 48%;
}

.pay_item {
    font-size: 16px;
    margin: 10px 0;
    font-weight: 600;
}

.label {
    font-weight: normal;
    display: inline-block;
    width: 150px;
    text-align: left;
    padding-right: 10px;
}

.price {
    color: red;
}

.pay_wrap {
    display: inline-block;
}

.radio_wrap {
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.logo {
    /* position: relative;
    top: 8px; */
    height: 34px;
    display: inline-block;
    transform: translateY(6px);

}

.modalcss {
    border-radius: 10px;
}

.yongjingbox {
    margin-top: 15px;
    border-top: 1px solid #E4E4E4;
    border-bottom: 1px solid #E4E4E4;
    padding: 25px 0;
}

.yongjingtxt {
    color: #36A096;
    font-size: 16px;
}

.yongjing {
    color: #F84C4C;
    font-weight: bold;
    font-size: 16px;
}

.termsbox {
    padding-top: 10px;
    font-size: 13px;

}

.termsboxlink {
    font-size: 13px;
    cursor: pointer;
    color: #888;
    text-decoration: underline;
}

.notepaytitle {
    padding-top: 20px;
    font-size: 16px;
    text-align: center;
}

.notepaytxt {
    color: #00f;
}
.pay_Tips{
    color: #666;
    font-size: 13px;
}

.pay_InfoBtn{
    border: 0;
    line-height: 17px;
    margin: 0;
    padding: 0;
    height: 22px;
}

.pay_OrderInfoItem{
    margin-top: 20px;
    border-bottom: 1px solid #E4E4E4;
    padding-bottom: 20px;
}