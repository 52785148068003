.ant-collapse-content-box p {
    padding-top: 10px;
    margin-bottom: 0;
}

.site-back-top-basic {
    color: rgba(64, 64, 64, 0.6);
}

.ant-collapse-header {
    font-weight: bold;
}

.Pagination-center {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
}

.ant-collapse-header {
    border-bottom: 1px solid #f2f2ff !important;
}