.Getrewards_main {
    padding: 30px 40px 60px;
    background-color: #fff;
    border-radius: 5px;
    min-height: 500px;
}

/* share */
.Sharelinkbox {
    background: #F0F3FB;
    width: 100%;
    border-radius: 5px;
    padding: 50px 0;
}

.Sharelinkhead {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    color: #3A3A3A;
}

.Sharelinkbody {
    font-size: 16px;
    text-align: center;
    color: #3B3B3B;
    margin-top: 10px;
    margin-bottom: 30px;
}

.sharelinkcopy {
    border-radius: 2px;
    overflow: hidden;
    width: 95%;
    margin: 0 auto;
    display: flex;
}

.sharelink {
    flex: 1;
    background: #fff;
    height: 50px;
    line-height: 45px;
    padding-left: 20px;
    color: #3B3B3B;
    font-size: 16px;
}

.sharelinkhref {
    color: #249FEC;
    margin-left: 5px;
}

.sharelinkcopybtn {
    background: #36A096;
    padding: 0 50px;
    line-height: 50px;
    color: #fff;
    cursor: pointer;
    transition: opacity 0.5s;
    font-weight: 500;
}


.sharelinkcopybtn:hover {
    opacity: 0.8;
}

.sharetag2withdraw {
    display: block;
    margin: 0 auto;
    height: 30px;
    line-height: 28px;
    background: #fff;
    border: 0;
    color: #249FEC;
    font-size: 15px;
    border-radius: 50px;
    padding: 0 40px;
    margin-top: 10px;
    cursor: pointer;
    transition: all 0.5s;
}

.sharetag2withdraw:hover {
    background: #d1eaf9;
}

/* howitwork or shareinfo */

.sharetag {
    padding-top: 50px;
}

.sharetag1 {
    width: 40%;
    float: left;
}


.sharetag1head {
    font-size: 16px;
    font-weight: bold;
}

.sharetag1step {
    list-style: none;
    padding-left: 0;
    font-size: 16px;
    color: #3A3A3A;
    margin-bottom: 10px;
    margin-top: 15px;
}

.sharetag1learn {
    font-size: 16px;
    color: #249FEC;
    cursor: pointer;
}


.sharetag2 {
    width: 60%;
    float: left;
    text-align: center;
}

.shareinfo {
    border: 1px solid #F0F3FB;
    border-radius: 10px;
}

.shareinfoitem {
    width: 33.3%;
    height: 240px;
    float: left;
    text-align: center;
    padding: 45px 0 30px;
}

.Friendsnum {
    font-size: 60px;
    color: #36A096;
    height: 100px;
    font-weight: 600;
}

.Friendsday {
    padding-left: 8px;
    font-size: 16px;
    color: #999999;
    font-weight: normal;
}

.cashprice {
    font-size: 60px;
    height: 100px;
    color: #F84C4C;
    font-weight: 600;
}

.borderright {
    border-right: 1px solid #F0F3FB;
}

.Friendstxt {
    color: #3A3A3A;
    font-size: 16px;
    margin-bottom: 12px;
}

.listnum {
    display: inline-block;
    height: 28px;
    width: 28px;
    line-height: 28px;
    text-align: center;
    background: #f2f2f2;
    border-radius: 15px;
    margin-right: 8px;
}

.sharetag1stepli {
    margin-bottom: 20px;
}