.head_tab{
    margin: 10px 0;
}
.head_tab_main{
    background-color: #fff;
    padding: 17px 0;
    border-radius: 5px;
}
.tab_link{
    display: inline-block;
    padding: 0 30px;
    color: #3a3a3a;
    font-weight: bold;
}
.home{
    margin-right: 40px;
    color: #999;
}