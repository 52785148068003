.changeaccountinput {
    height: 40px;
}

.changeaccounttxt {
    font-size: 14px;
    margin-top: 10px;
}

.changeaccountbtn {
    display: block;
    background: #36A096;
    color: #fff;
    border-radius: 50px;
    height: 40px;
    font-size: 16px;
    padding: 5px 40px;
    margin: 0 auto;
    border: 0;
    outline: none;
}

.changeaccountbtn:hover {
    background: #36A096;
    color: #fff;
    opacity: 0.8;
}

.changeaccountbtn:focus {
    background: #36A096;
    color: #fff;
}

.changeaccountbtn:active {
    display: block;
    background: #36A096;
    color: #fff;
    border-radius: 50px;
    height: 40px;
    font-size: 16px;
    padding: 5px 40px;
    margin: 0 auto;
    border: 0;
}

.changeaccounttx {
    margin-bottom: 10px;
}

.errormsg {
    color: red;
    margin-bottom: 0;
}