.main{
    width: 600px;
    background-color: #fff;
    min-height: 300px;
    border-radius: 20px;
    padding: 10px 20px 10px;
    margin: 20px auto;
}
.textarea_input textarea{
    resize: none;
}
.footer_btn{
    width: 400px;
    margin: 20px auto;
}