.news_main{
    /* min-width: 1200px; */
   
}
.sasd{
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently not supported by any browser */
}
.main{
    padding: 20px 40px;
    background-color: #fff;
    border-radius: 5px;
    min-height: 500px;
}
.title{
    font-weight: bold;
    font-size: 16px;
}
.time{
    color: #666;
    font-size: 14px;
}