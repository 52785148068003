.head {
  /* min-width: 1200px; */
  padding-top: 20px;
}

.head_main {
  background-color: #36a096;
  height: 100px;
  border-radius: 5px;
  padding: 30px 40px;
}

.imgicon {
  display: inline-block;
  width: 16px;
  height: 16px;
  transform: translateY(-2px);
}

.name,
.name:hover {
  color: #fff;
}

.head_tab {
  margin: 10px 0;
}

.head_tab_main {
  background-color: #fff;
  padding: 17px 0;
  border-radius: 5px;
}

.tab_link {
  display: inline-block;
  padding: 0 30px;
  color: #3a3a3a;
  font-weight: bold;
  cursor: pointer;
}

.word {
  margin-left: 5px;
}

.user {
  display: inline-block;
  color: #fff;
  margin-left: 20px;
  position: relative;
  top: 5px;
}

.name {
  font-weight: bold;
  font-size: 18px;
}

.version {
  color: #eee;
}

.expair {
  height: 40px;
  padding: 0 20px;
  border-radius: 5px;
  background-color: #fff;
  color: #249fec;
  line-height: 40px;
}

.link {
  color: #3a3a3a;
  display: block;
  width: 120px;
  line-height: 30px;
}

.overduedayclass {
  color: red;
}

.SubscriptionIconBox{
  width: 16px;
  height: 18px;
  display: inline-block;
  transform: translateY(-2px);
}