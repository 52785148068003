.refox_case_main{
    /* min-width: 1200px; */
}
.main{
    background-color: #fff;
    margin: 0 auto;
    border-radius: 5px;
    overflow: hidden;
}
.case_nav{
    width: 300px;
}
