.Promotedetails_main {
    padding: 20px 20px 40px;
    background-color: #fff;
    border-radius: 5px;
    min-height: 500px;
}

.Promotedetailsprice {
    font-size: 16px;
}

.Promotedetailspricenum {
    margin-left: 10px;
    font-size: 24px;
    font-weight: bold;
    color: #F84C4C;
}

.Promotedetailswithdrawal {
    margin-top: 20px;
    font-size: 16px;
    margin-bottom: 30px;
}

.Promotedetailsbtntxt {
    display: inline-block;
    margin-right: 5px;
    color: #3A3A3A;
}

.Promotedetailsbtn1 {
    padding: 10px;
    color: #999;
    cursor: pointer;
}

.Promotedetailsbtn2 {
    padding: 10px;
    color: #249FEC;
    cursor: pointer;
}

.btnclass {
    padding: 0 10px;
}

.promotetabsbox {
    margin-top: 20px;
}



.changeaccountinput {
    height: 40px;
}

.changeaccounttxt {
    font-size: 14px;
    margin-top: 10px;
}

.changeaccountbtn {
    display: block;
    background: #36A096;
    color: #fff;
    border-radius: 50px;
    height: 40px;
    font-size: 16px;
    padding: 5px 40px;
    margin: 0 auto;
    border: 0;
    outline: none;
}

.changeaccountbtn:hover {
    background: #36A096;
    color: #fff;
    opacity: 0.8;
}

.changeaccountbtn:focus {
    background: #36A096;
    color: #fff;
}

.changeaccountbtn:active {
    display: block;
    background: #36A096;
    color: #fff;
    border-radius: 50px;
    height: 40px;
    font-size: 16px;
    padding: 5px 40px;
    margin: 0 auto;
    border: 0;
}

.changeaccounttx {
    margin-bottom: 10px;
}

.withdrawbtn {
    font-size: 15px;
    margin-left: 10px;
    color: #249FEC;
    cursor: pointer;
}

.errormsg {
    color: red;
    margin-top: 5px;
    margin-bottom: 0;
}