.subscriptionMain{
    padding:  0;
}
.InvoicesDownloadEmail{
    padding-bottom: 20px;
    line-height: 28px;
    font-size: 14px;
    border-bottom: 1px solid #E4E4E4;
    margin-bottom: 20px;
}
.InvoicesDownloadtxt{
    color: #249FEC;
}

.InvoicesDownloadEmail2{
    padding-bottom: 10px;
    line-height: 28px;
    font-size: 14px;
    color: #999999;
}

.InvoicesDownloadtxt2{
    margin-left: 15px;
    color: #249FEC;
    text-decoration: underline;
    cursor: pointer;
}

.InvoicesDownloadtxt3{
    color: #000;
    font-size: 14px;
    font-weight: 600;
}

.AddInvoices{
    background: #36A096;
    color: #fff;
    width: 60%;
    text-align: center;
    display: block;
    margin: 0 auto;
    border-radius: 4px;
    height: 38px;
    line-height: 38px;
    border: 0;
    cursor: pointer;
}

.InvoicesDownloadTable{
    width: 100%;
}
.InvoicesDownloadTable td{
    height: 40px;
    line-height: 40px;
}

.InvoicesDownloadTableThead{
    background: #F5FAFA;
    
}
.InvoicesDownloadTableThead td{
    padding: 0 10px;
}

.InvoicesDownloadTableTBody td{
    padding: 0 10px;
    border-bottom: 1px solid #f2f2f2;
}

.InvoicesDownloadImg{
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.InvoicesDownloadBtn:hover{
background-color: rgb(223, 223, 223) !important;
color: #000;
}