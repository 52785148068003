.order_record_main{
    /* min-width: 1200px; */
}
.main{
    padding: 20px 40px;
    background-color: #fff;
    border-radius: 5px;
    min-height: 500px;
}
.title{
    font-weight: bold;
    font-size: 16px;
}
.time{
    color: #666;
    font-size: 14px;
}
.btn_pay{
    width: 160px;
    margin-bottom: 10px;
    display: block;
}
.btn_cancel{
    width: 160px;
}
.EditImg{
    width: 22px;
    height: auto;
    margin-right: 5px;
    font-weight: 500;
}

.Headtitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.EditBtn{
    display: flex;
    align-items: center;
    background: #36A096;
    color: #fff;
    padding: 5px 8px;
    border-radius: 4px;
    cursor: pointer;
}

.reloadPageIcon{
    width: 100px;
    height: auto;
    display: block;
    margin: 0 auto 30px;
  }
  .reloadPageText{
    text-align: center;
    line-height: 30px;
    font-size: 15px;
  }
  
  .reloadPageButton{
    background: #36A096;
    line-height: 38px;
    margin: 0 auto;
    display: block;
    color: #fff;
    padding: 0 32px;
    border-radius: 20px;
    border: 0;
    cursor: pointer;
    margin-bottom: 15px;
    margin-top: 10px;
  }
  
  .reloadPageButton:hover{
    opacity: 0.7;
  }
  
  