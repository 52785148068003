.main{
    width: 600px;
    background-color: #fff;
    min-height: 300px;
    border-radius: 20px;
    padding: 10px 20px 10px;
    margin: 20px auto;
}
.textarea_input textarea{
    resize: none;
}
.footer_btn{
    width: 400px;
    margin: 20px auto;
}

.CencelBtnContenForm{
    border-top: 1px solid #f2f2f2;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
}

.CencelBtnConten{
    display: flex;
    justify-content: center;
    margin-bottom: 0;
}

.CencelBtn{
    width: 30%;
    margin: 0 20px;
    height: 40px;
    font-size: 15px;
    border-radius: 20px;
    background: #f2f2f2;
}

.SubmitBtn{
    width: 30%;
    margin: 0 20px;
    height: 40px;
    font-size: 15px;
    border-radius: 20px;
    background: #36a096 !important;
    outline: none;
}

.SubmitBtn:active{
    background: #36a096;
}